<template>
  <!-- Modal Seleccionar Dirección -->
  <mdb-modal
    :show="mostrarModal"
    centered
    elegant
    scrollable
    size="lg"
    @close="limpiarCampos(); $emit('close', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>Asignar dirección al paquete</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div v-if="direccionesDelCliente && direccionesDelCliente.length">
        <ul class="list-unstyled mb-0">
          <li
            v-for="(direccion, iD) in direccionesDelCliente"
            :key="`direccion${iD}`"
          >
            <label
              v-if="direccionesDelCliente.length > 1"
              class="opciones-radio ml-0"
              title="Seleccionar dirección"
            >
              <input
                v-model="DireccionSeleccionada"
                type="radio"
                name="radio-direcciones"
                :value="direccion"
              >
              <span class="icono-radio" />
              Dirección {{iD+1}}:
            </label>
            <div class="row my-2">
              <p class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Dirección de envío
                </small>
                {{ direccion.direccion }}.
                <span class="d-block">
                  {{ direccion.ciudad.nombre }},
                  {{ direccion.ciudad.estado.nombre }},
                  {{ direccion.ciudad.estado.pais.nombre }}.
                </span>
              </p>
              <p class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Observación
                </small>
                <span
                  :class="{'text-muted user-select-none': !direccion.observacion}"
                >
                  {{ direccion.observacion ? direccion.observacion : 'Sin especificar' }}
                </span>
              </p>
              <div class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Persona que recibe
                </small>
                {{ direccion.recibe && direccion.recibe.nombre ?  direccion.recibe.nombre : 'Sin especificar'}}
              </div>
              <div
                v-if="direccion.recibe"
                class="col-12 col-lg-6 mb-2"
              >
                <small class="text-muted user-select-none d-block">
                  Teléfonos
                </small>
                <ul
                  v-if="direccion.recibe.telefonos && direccion.recibe.telefonos.length"
                  class="list-unstyled mb-0"
                >
                  <li
                    v-for="(tel, i) in direccion.recibe.telefonos"
                    :key="`tel${i}`"
                    class="d-inline"
                  >
                    {{i > 0 ? ', ' : ''}}
                    <a
                      :href="`tel:+${tel.numero}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{{tel.numero}}
                    </a>
                  </li>
                </ul>
                <span v-else class="text-muted user-select-none">
                  Sin especificar
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="paqueteAsignarDirección()"
          >
            Asignar dirección
          </mdb-btn>
        </div>
      </div>
      <div
        v-else
        class="text-center text-muted user-select-none mb-4"
      >
        <font-awesome-icon
          :icon="['fas', 'map-signs']"
          class="fa-5x mb-4"
        />
        <p class="h3-responsive text-center mb-0 mx-lg-5 px-lg-5">
          No tienes direcciones registradas.
          Por favor agrega una dirección para continuar.
        </p>
      </div>
      <!-- Sección instruccion envio -->
      <template v-if="paqueteSeleccionado && paqueteSeleccionado.estatus && paqueteSeleccionado.estatus === Object.keys(PaqueteEstatus).at(0) && $route.params.paqueteId">
      <hr>
        <header class="font-weight-bold mt-2">Asignar el tipo de envío deseado</header>
         <div class="md-form md-outline outline-select my-2">
          <select
            id="tipo-select"
            v-model="paquete.envio"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(key, value) in ConsolidadoTipoEnvio"
              :key="key"
              :value="value"
            >
              {{ key }}
            </option>
          </select>
        </div>
          <div class="row justify-content-center mt-3">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="px-3"
            :disabled="botonDeshabilitado || !paquete.envio"
            @click="validarTipoEnvio"
          >
            Agregar
          </mdb-btn>
        </div>
      </template>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import gql from 'graphql-tag'
import clienteBuscarDireccionesGql from '@/graphql/clienteBuscarDirecciones.gql'
import paqueteAsignarDestinoGql from '@/graphql/paqueteAsignarDestino.gql'
import paqueteModificarEnvioGql from '@/graphql/paqueteModificarEnvio.gql'
import { ConsolidadoTipoEnvio } from '@/constantes/consolidados.js'
import { PaqueteEstatus } from '@/constantes/paquetes.js'
import { paqueteEsRetrocesoEstatus } from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'

export default {
  name: 'ModalSeleccionarDireccion',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    paqueteSeleccionado: {
      type: Object,
      required: true
    },
    clienteId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ConsolidadoTipoEnvio,
      PaqueteEstatus,
      botonDeshabilitado: false,
      DireccionSeleccionada: '',
      direccionesDelCliente: [],
      paquete: {
        envio: ''
      }
    }
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos()
        this.botonDeshabilitado = false
      } else {
        this.clienteBuscarDirecciones()
      }
    }
  },
  methods: {
    alertaMensaje (contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo
      })
    },
    paqueteAsignarDirección () {
      if (this.direccionesDelCliente.length !== 1 && !this.DireccionSeleccionada) {
        return this.alertaMensaje('Por favor selecciona una dirección antes de continuar',
          'advertencia')
      }
      if (this.direccionesDelCliente.length === 1 && this.paqueteSeleccionado.direccionDestino &&
        this.direccionesDelCliente[0].direccion === this.paqueteSeleccionado.direccionDestino.direccion) {
        return this.alertaMensaje('La dirección ya se encuentra asignada al paquete',
          'advertencia')
      }
      if (paqueteEsRetrocesoEstatus(this.paqueteSeleccionado.estatus, 'ProcesoAduana')) {
        return this.alertaMensaje('Esta acción solo puede realizarse en paquetes no procesados por Aduana',
          'advertencia')
      }
      const direccion = this.DireccionSeleccionada
        ? this.DireccionSeleccionada
        : this.direccionesDelCliente[0]
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarDestinoGql}
          `,
          variables: {
            paqueteId: this.paqueteSeleccionado.id,
            direccionId: direccion.id
          }
        })
        .then(({ data: { paqueteAsignarDestino } }) => {
          const msg = paqueteAsignarDestino.codigo
          const paquete = this.paqueteSeleccionado
          switch (msg) {
            case 'Correcto':
              // Asignar la dirección al paquete seleccionado
              paquete.direccionDestino = direccion
              this.$emit('paqueteConDireccion', paquete)
              this.direccionesDelCliente = []
              this.DireccionSeleccionada = ''
              this.alertaMensaje('Dirección asignada al paquete exitosamente', 'correcto')
              this.$emit('close', false)
              break
            default:
              this.alertaMensaje('Ha ocurrido un error. Asegurate que el estatus del paquete no se encuentre procesado por aduana', 'error')
              this.botonDeshabilitado = false
              break
          }
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.botonDeshabilitado = false
          return this.alertaMensaje('Ha ocurrido un error asignando la dirección al paquete.' +
                  ' Por favor intenta de nuevo', 'error')
        })
    },
    validarTipoEnvio () {
      this.botonDeshabilitado = true
      this.validarValores = {
        envio: this.paquete.envio.trim && this.paquete.envio.length ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false
        return (this.alertaMensaje('El tipo de envío es requerido', 'advertencia'))
      }
      this.instruccionEnvioCrear()
    },
    async instruccionEnvioCrear () {
      this.botonDeshabilitado = true
      const envio = this.paquete.envio
      const variablesMutate = {
        envio,
        paquetesId: [this.paqueteSeleccionado.id]
      }
      try {
        const { data: { paqueteModificarEnvio } } = await this.$apollo
          .mutate({
            mutation: paqueteModificarEnvioGql,
            variables: variablesMutate
          })
        const msg = paqueteModificarEnvio.codigo
        switch (msg) {
          case 'Correcto':
            this.alertaMensaje('Instrucción de envío creada correctamente.', 'correcto')
            break
          case 'Fallido':
            this.botonDeshabilitado = false
            return this.alertaMensaje('Ha ocurrido un error creando la instrucción de envio. Intente de nuevo',
              'advertencia')
          default:
            this.botonDeshabilitado = false
            return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
              'error')
        }
        this.paqueteSeleccionado.envio = envio
        this.$emit('close', false)
        this.botonDeshabilitado = false
      } catch (error) {
        this.alertaMensaje('Ha ocurrido un error inesperado. Intente nuevamente',
          'error')
        this.botonDeshabilitado = false
      }
    },
    clienteBuscarDirecciones () {
      const id = this.clienteId
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: gql`
            ${clienteBuscarDireccionesGql}
          `,
          variables: {
            id
          },
          update: (data) => data.Cliente[0],
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Cliente && data.Cliente[0].direcciones) {
              this.direccionesDelCliente = data.Cliente[0].direcciones
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las direcciones del cliente.' +
                  ' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false
          }
        })
    },
    limpiarCampos () {
      this.botonDeshabilitado = false
      this.DireccionSeleccionada = ''
      this.direccionesDelCliente = []
    }
  }
}

</script>

 <style lang="scss" scoped>

 </style>
